.input-wrapper input {
    border: 0 none;
    box-shadow: none;
    outline: none;
    padding-right: 0;
}

.input-wrapper input:focus {
    border: 0 none;
    box-shadow: none;
    outline: none;
}