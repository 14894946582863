.disable-product {
  cursor: not-allowed;
  z-index: 15;
  @apply transition ease-in-out;
}

.disable-product:hover {
  background: rgba(255, 255, 255, 0.7);
}

.disable-product-message {
  opacity: 0;
  @apply transition-opacity ease-in-out;
}

.disable-product:hover .disable-product-message {
  opacity: 1;
}